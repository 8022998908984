import * as amplitudeBase from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/nextjs';

const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

// TODO:
// 1. Create tracking plan
// 2. Implementation of helper function with pre-defined events

export function initAmplitude(): void {
  if (!amplitudeApiKey) {
    Sentry.captureMessage(
      'Amplitude API key is missing - Tracking has been disabled this visit. Please ensure the key is correctly set in the environment variable config.',
      'warning'
    );
    return;
  }

  amplitudeBase.init(amplitudeApiKey, {
    serverUrl:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/amplitude'
        : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/amplitude`,
  });
}

// Expected events for Amplitude
export enum TrackedEvent {
  LESSON_STARTED = 'Lesson start',
  LESSON_COMPLETED = 'Lesson complete',
}

type AmplitudeTrack = (typeof amplitudeBase)['track'];

// intersecting lets us narrow the type of the 0th param
type AmplitudeTrackNarrowedParams = Parameters<AmplitudeTrack> &
  [eventInput: TrackedEvent | amplitudeBase.Types.BaseEvent, ...rest: any[]];

// we keep all other properties but redefine the type of `.track`
const amplitude = amplitudeBase as Omit<typeof amplitudeBase, 'track'> & {
  track: (
    ...params: AmplitudeTrackNarrowedParams
  ) => ReturnType<AmplitudeTrack>;
};

// --- Utility functions ---

export function addAmplitudeUserProperties(
  propertyKey: string,
  propertyValue: string | number | boolean
): void {
  const identifyEvent = new amplitudeBase.Identify();
  identifyEvent.set(propertyKey, propertyValue);

  amplitude.identify(identifyEvent);
}

export default amplitude;
