import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { addAmplitudeUserProperties } from '../amplitude/amplitude';
import { useSessionStorage } from 'usehooks-ts';

function useCampaignTracking() {
  const [, setUtmSource] = useSessionStorage('utmSource', '');
  const [, setUtmCampaign] = useSessionStorage('utmCampaign', '');
  const router = useRouter();

  useEffect(() => {
    // On page initial load and when router is ready
    if (router.isReady) {
      // Grab source and campaign query params from URL
      const { utm_source, utm_campaign } = router.query;

      // Set Amplitude user properties if they exist
      if (utm_source && typeof utm_source === 'string') {
        addAmplitudeUserProperties('utm_source', utm_source);
        setUtmSource(utm_source);
      }
      if (utm_campaign && typeof utm_campaign === 'string') {
        addAmplitudeUserProperties('utm_campaign', utm_campaign);
        setUtmCampaign(utm_campaign);
      }
    }
  }, [router, setUtmSource, setUtmCampaign]);
}

export default useCampaignTracking;
