export function storeNavigationPaths(): void {
  const storage = globalThis?.sessionStorage;

  if (!storage) return;

  // If there is no current path -> assumption that this is the first page load.
  const prevPath = storage.getItem('currentPath');
  if (prevPath) {
    storage.setItem('prevPath', prevPath);
  }

  // Set the current path value by looking at the browser's location object.
  const currentPath = globalThis.location.pathname || '';
  storage.setItem('currentPath', currentPath);
}

export function getPreviousNavigationPath(): string | undefined {
  const storage = globalThis?.sessionStorage;

  if (!storage) return undefined;

  return storage.getItem('prevPath') || undefined;
}
