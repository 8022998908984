import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthContext } from './AuthContext';
import axios from 'axios';
import { getAuthHeaders } from '@/lib/axios/utils';
import { UserDoc } from 'server/types/databaseDocumentTypes/UserDoc';
import { useImmer } from 'use-immer';
import { WorkPreferencesDoc } from 'server/types/databaseDocumentTypes/UserWorkPreferenceDoc';
import { set } from 'lodash';

import { Course } from '@/data/lesson.types';

export interface LearnerContext {
  state: {
    learner: Partial<UserDoc>;
    learnerWorkPreferences: Partial<WorkPreferencesDoc>;
    isLoading: boolean;
    selectedCourse: Course | null;
  };
  handlers: {
    handleFetchUserProfile: () => Promise<void>;
    setSelectedCourse: (course: Course | null) => void;
  };
}

export const LearnerContext = createContext<LearnerContext>({
  state: {
    learner: {},
    learnerWorkPreferences: {},
    isLoading: true,
    selectedCourse: null,
  },
  handlers: {
    handleFetchUserProfile: async () => {},
    setSelectedCourse: () => {},
  },
});

type LearnerContextState = {
  email: string | undefined;
  preferredFirstName: string | undefined;
  userUid: string | undefined;
};

// Context provider to be wrapped around root component
export default function LearnerContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const [learner, setLearner] = useImmer<Partial<UserDoc>>({});
  const [learnerWorkPreferences, setLearnerWorkPreferences] = useImmer<
    Partial<WorkPreferencesDoc>
  >({});
  const [isLoading, setIsLoading] = useState(true);

  const [{ isReady }, { getUserUid, getUserAccessToken }] = useAuthContext();

  // state for a course that is selected by the user
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);

  // Handlers for updating state
  // Handler to fetch user profile of current user from server
  const handleFetchUserProfile = useCallback(async () => {
    setIsLoading(true);
    const userUid = getUserUid();
    const userAccessToken = (await getUserAccessToken()) || '';

    if (!userUid) return;

    const learnerResponse = await axios.get(
      `/api/user/${userUid}`,
      getAuthHeaders(userAccessToken)
    );
    const learnerWorkPreferencesResponse = await axios.get(
      `/api/user/${userUid}/work-preferences`,
      getAuthHeaders(userAccessToken)
    );

    const user = learnerResponse.data.user as Partial<UserDoc>;
    const workPreferences = learnerWorkPreferencesResponse.data.workPreferences;

    setLearner(user);
    setLearnerWorkPreferences(workPreferences);
    setIsLoading(false);
  }, [getUserUid, getUserAccessToken, setLearner, setLearnerWorkPreferences]);

  // This useEffect runs handlers that depend on the AuthContext being ready
  useEffect(() => {
    if (!isReady) return;

    handleFetchUserProfile();
  }, [isReady, handleFetchUserProfile]);

  return (
    <LearnerContext.Provider
      value={{
        state: { learner, learnerWorkPreferences, isLoading, selectedCourse },
        handlers: {
          handleFetchUserProfile,
          setSelectedCourse,
        },
      }}
    >
      {children}
    </LearnerContext.Provider>
  );
}

// Hook to access state and handlers stored in context
export function useLearnerContext(): [
  LearnerContext['state'],
  LearnerContext['handlers']
] {
  const { state, handlers } = useContext(LearnerContext);
  return [state, handlers];
}

// ------------------ //
// Immer utils for updating state
// ------------------ //

const replacePropertyValue = <T extends keyof LearnerContextState>(
  draft: LearnerContextState,
  key: T,
  replaceValueWith: LearnerContextState[T]
) => {
  draft[key] = replaceValueWith;
};
