import * as Realm from 'realm-web';
import { handleAuthenticationError } from './utils';
import * as Sentry from '@sentry/nextjs';
import { PASSWORD_RESET_ERROR_MESSAGES } from '@/utils/constants';

export type SignupLoginError = {
  error: string;
};

// Get App Id
const appId = process.env.NEXT_PUBLIC_MONGO_APP_ID;

export type MongoRealmInstance =
  Realm.App<globalThis.Realm.DefaultFunctionsFactory>;
/*
 * This function initializes a new Realm app instance using the App ID from our environment variables.
 */
function getMongoRealm(): MongoRealmInstance {
  if (appId === undefined) {
    throw new Error(
      'No App Id was found. Please check your environment variables for an App ID used for MongoDB Realm/App Services.'
    );
  }

  // Initialize app service instance
  const app = new Realm.App({ id: appId });

  return app;
}

/*
 * This function registers a new user using the email/password authentication method within the Realm SDK.
 * @param email - The email address of the user to register
 * @param password - The password of the user to register
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 * @returns - Returns a promise that resolves to the user object if successful, or an error if unsuccessful
 */
async function registerNewUser(
  email: string,
  password: string,
  realmInstance: MongoRealmInstance = getMongoRealm()
): Promise<Realm.User | SignupLoginError> {
  const emailStandarised = email.toLowerCase().trim();
  return emailPasswordSignup(emailStandarised, password, realmInstance);
}

/*
 * This function registers a new user using the email/password authentication method within the Realm SDK and then logs them in.
 * @param email - The email address of the user to register
 * @param password - The password of the user to register
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 * @returns - Returns a promise that resolves to the user object if successful, or a SignupLoginError object if unsuccessful
 */
const emailPasswordSignup = async (
  email: string,
  password: string,
  realmInstance: MongoRealmInstance = getMongoRealm()
): Promise<Realm.User | SignupLoginError> => {
  try {
    await realmInstance.emailPasswordAuth.registerUser({ email, password });

    // Login is required to fetch the user object after a successful registration
    const loginResult = emailPasswordLogin(email, password, realmInstance);

    return loginResult;
  } catch (error: any) {
    const errorMessage =
      handleAuthenticationError(error) || 'Unknown error whilst signing up';
    console.error('Error signing up new user:', errorMessage);

    return { error: errorMessage };
  }
};

/*
 * This function logs in a user using the email/password authentication method within the Realm SDK and returns the representation of the logged in user.
 * @param email - The email address of the user to login
 * @param password - The password of the user to login
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 * @returns - Returns a promise that resolves to the user object if successful, or a SignupLoginError object if unsuccessful
 */
const emailPasswordLogin = async (
  email: string,
  password: string,
  realmInstance: MongoRealmInstance = getMongoRealm()
): Promise<Realm.User | SignupLoginError> => {
  try {
    const credentials = Realm.Credentials.emailPassword(email, password);
    const authedUser = await realmInstance.logIn(credentials);

    return authedUser;
  } catch (error) {
    const errorMessage =
      handleAuthenticationError(error) || 'Unknown error while logging in';
    console.error('Error loggin in user:', errorMessage);

    return { error: errorMessage };
  }
};

type UserOrCredentials = Realm.User | { email: string; password: string };

/*
 * This function deletes a user from the Realm app instance. If the user is not authenticated, they will be authenticated first.
 * @param userToDelete - The user to delete from the Realm app instance (either a Realm.User object or an object containing the email and password of the user to delete)
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 * @returns - Returns a promise that resolves to the user object if successful, or a SignupLoginError object if unsuccessful
 */
const deleteUser = async (
  userToDelete: UserOrCredentials,
  realmInstance: MongoRealmInstance = getMongoRealm()
) => {
  if ('email' in userToDelete && 'password' in userToDelete) {
    // User is not authenticated, so we need to authenticate them first
    const { email, password } = userToDelete;
    const authedUser = await emailPasswordLogin(email, password);

    if ('error' in authedUser || !realmInstance.currentUser) {
      console.error('Error deleting user as we could not authenticate them');
      return authedUser;
    }

    return realmInstance.deleteUser(realmInstance.currentUser);
  } else {
    if (!realmInstance.currentUser) {
      // In reality this shouldn't happen as we would only call this function if the user was authenticated, however this is a safety check
      throw new Error(
        "There was mismatch when deleting a user account/resetting registration. A user object was provided, but the user wasn't authenticated."
      );
    }

    // User is already authenticated, so the user has permission to be deleted
    await realmInstance.deleteUser(realmInstance.currentUser);
    return;
  }
};

/*
 * This function sends a password reset email to the specified email address (if they are a registered user).
 * @param email - The email address of the user to send the password reset email to
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 */
async function sendPasswordResetEmail(
  email: string,
  realmInstance: MongoRealmInstance = getMongoRealm()
) {
  try {
    await realmInstance.emailPasswordAuth.sendResetPasswordEmail({ email });
  } catch (error: any) {
    // Do nothing if the error is UserNotFound, as we don't want to leak information about whether a user is registered or not
    // otherwise we will log the issue to Sentry
    if (error.errorCode !== 'UserNotFound') {
      Sentry.captureMessage(
        'Unhandled error code from Realm during password reset:' + error,
        'error'
      );
    }
  }
}

export type KnownResetErrorsCodes = 'BadRequest' | 'UserpassTokenInvalid';
export type SuccessResetStatus = { status: 'success' };
export type ErrorResetStatus = {
  status: 'Error' | 'UnknownError' | KnownResetErrorsCodes;
  error: string;
};
export type ResetPasswordStatus = SuccessResetStatus | ErrorResetStatus;

/*
 * This function resets the password of a user using a token and token ID.
 * @param token - The token to use to reset the password
 * @param tokenId - The token ID to use to reset the password
 * @param newPassword - The new password to set for the user
 * @param realmInstance - The Realm app instance to use for authentication. Defaults to the instance returned by getMongoRealm()
 * @returns - Returns a promise that resolves to a status object with a status of 'success' if successful, or a status of 'error' if unsuccessful
 */
async function resetPasswordWithToken(
  token: string,
  tokenId: string,
  newPassword: string,
  realmInstance: Realm.App = getMongoRealm()
): Promise<ResetPasswordStatus> {
  try {
    await realmInstance.emailPasswordAuth.resetPassword({
      password: newPassword,
      token,
      tokenId,
    });
    return { status: 'success' };
  } catch (error: any) {
    // Instance of MongoDBRealmError from realm-web
    if (
      error.errorCode === 'BadRequest' ||
      error.errorCode === 'UserpassTokenInvalid'
    ) {
      return {
        status: 'UserpassTokenInvalid',
        error: PASSWORD_RESET_ERROR_MESSAGES.EXPIRED_LINK,
      };
    } else {
      Sentry.captureMessage(
        'Unhandled error code from Realm during password reset:' +
          error.errorCode,
        'error'
      );
      return {
        status: 'UnknownError',
        error: PASSWORD_RESET_ERROR_MESSAGES.UNKNOWN_ERROR,
      };
    }
  }
}

export default getMongoRealm;
export {
  registerNewUser,
  emailPasswordSignup,
  emailPasswordLogin,
  deleteUser,
  sendPasswordResetEmail,
  resetPasswordWithToken,
};
