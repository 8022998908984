import { useEffect } from 'react';

function useMobileVH(): void {
  useEffect(() => {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html#comment-4634921967
    function setDocHeight() {
      const vh100Base = Math.min(
        document.documentElement.clientHeight,
        window.innerHeight
      );

      document.documentElement.style.setProperty(
        '--vh',
        `${vh100Base / 100}px`
      );
    }

    setDocHeight();

    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    return function cleanup() {
      window.removeEventListener('resize', setDocHeight);
      window.removeEventListener('orientationchange', setDocHeight);
    };
  });
}

export default useMobileVH;
