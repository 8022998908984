import AuthContextProvider from '@/contexts/AuthContext';
import LearnerContextProvider from '@/contexts/LearnerContext';
import { initAmplitude } from '@/lib/amplitude/amplitude';
import useCampaignTracking from '@/lib/useCampaignTracking';
import useMobileVH from '@/lib/useMobileVH';
import '@/styles/globals.css';
import { GetLayout, NextPageWithLayout } from '@/utils/next-layout.types';
import { storeNavigationPaths } from '@/utils/paths';
import '@greenworkx/openlearn/dist/style.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? getMobileFrameLayout;

  useMobileVH();
  useCampaignTracking();

  useEffect(() => {
    if (router.isReady) {
      initAmplitude();
    }
  }, [router.isReady]);

  // To track of current and referrer path in session storage
  useEffect(() => storeNavigationPaths, [router.asPath]);

  return (
    <>
      <AuthContextProvider>
        <LearnerContextProvider>
          <Head>
            <title>Greenworkx | Green Learning and Job Opportunities</title>
            <meta
              name='description'
              content='Find green job resources and opportunities that are right for you.'
            />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <link rel='icon' href='/favicon.ico' />
          </Head>
          {getLayout(<Component {...pageProps} />)}
        </LearnerContextProvider>
      </AuthContextProvider>
    </>
  );
}

export const getMobileFrameLayout: GetLayout = (page) => (
  <div className='bg-white h-viewport w-full fixed top-0 left-0 flex justify-center items-center md:px-[20%] md:py-[5%] overflow-y-auto overflow-x-hidden'>
    <div className='max-w-[800px] max-h-[1000px] w-full h-full'>{page}</div>
  </div>
);
