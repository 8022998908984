import * as Realm from 'realm-web';

/*
 * This function handles authentication errors and returns a string that can be used to display
 * an error message to the user within the UI
 *
 * Error codes and messages are from the Mongo Community Forum (employee post):
 * https://www.mongodb.com/community/forums/t/how-could-i-know-all-about-error-codes/110634/3?u=matthew_man
 */
export function handleAuthenticationError(err: any): string | void {
  const handleUnknownError = () => {
    console.warn(
      'Something went wrong with a Realm login or signup request. See the following error for details.'
    );
    console.error(err);
  };

  if (err instanceof Realm.MongoDBRealmError) {
    const { error, statusCode } = err;
    const errorType = error || statusCode;

    switch (errorType) {
      case 'name already in use':
      case 409:
        // Email is already registered
        return 'This email address is already registered. Please try logging in instead.';
      case 'invalid username':
      case 'invalid username/password':
      case 'invalid password':
      case 401:
        // Invalid username/password combination
        return 'The email address or password you entered is incorrect. Please try again.';
      case 'password must be between 6 and 128 characters':
      case 400:
        // Technicall MongoDB Realm allows passwords from 6 characters, but we're limiting it to 8 for now in the app.
        return 'Your password must be between 8 and 128 characters long. Please try again.';
      default:
        // In theory you won't ever hit this default, but if an error message/code without a case ever comes up it will fall back to this.
        handleUnknownError();
        break;
    }
  } else {
    // In this case, the error isn't a MongoDB Realm error so you probably need to add another error handler somewhere else to catch it before it gets passed to this function.
    handleUnknownError();
  }
}
