import { emailPasswordLogin, useRealmApp } from '@/lib/mongoRealm';
import {
  MongoRealmInstance,
  SignupLoginError,
} from '@/lib/mongoRealm/mongoRealm';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User } from 'realm-web';

export interface AuthContext {
  state: {
    realmApp: MongoRealmInstance | null;
    isReady: boolean;
  };
  handlers: {
    getUserUid: () => string | null;
    getUserEmail: () => string | null;
    getRoles: () => string[];
    getActiveUser: () => User | null;
    getUserAccessToken: () => Promise<string | null>;
    logInUser: (
      email: string,
      password: string
    ) => Promise<SignupLoginError | string>;
    logOutUser: () => Promise<boolean>;
  };
}

export const AuthContext = createContext<AuthContext>({
  state: {
    realmApp: null,
    isReady: false,
  },
  handlers: {
    getUserUid: () => null,
    getUserEmail: () => null,
    getRoles: () => [],
    getActiveUser: () => null,
    getUserAccessToken: () => Promise.resolve(null),
    logInUser: () => Promise.resolve({ error: 'Not implemented' }),
    logOutUser: () => Promise.resolve(false),
  },
});

export default function AuthContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const realmApp = useRealmApp();
  const [isReady, setIsReady] = useState(true);

  // Handlers ---------------------------------------------------------
  const getActiveProfile = useCallback(
    () => realmApp?.currentUser?.profile || null,
    [realmApp]
  );

  const getUserUid = useCallback(() => {
    return realmApp?.currentUser?.id || null;
  }, [realmApp]);

  const getUserEmail = useCallback(
    () => getActiveProfile()?.email || null,
    [getActiveProfile]
  );

  const getRoles = useCallback(
    () => (realmApp?.currentUser?.customData?.roles as Array<string>) || [],
    [realmApp]
  );

  const getUserAccessToken = useCallback(async () => {
    if (!realmApp || !realmApp.currentUser) return null;
    await realmApp.currentUser.refreshAccessToken();
    return realmApp.currentUser.accessToken || null;
  }, [realmApp]);

  const getActiveUser = useCallback(
    () => realmApp?.currentUser || null,
    [realmApp]
  );
  // ------------------------------------------------------------------

  /*
   * This function logs in a user and updates the auth state
   * @param email - the email of the user to log in
   * @param password - the password of the user to log in
   * @returns - an error if there is one, otherwise the user's UID
   */
  const logInUser = async (
    email: string,
    password: string
  ): Promise<SignupLoginError | string> => {
    if (realmApp === null) return { error: 'Realm app is null' };

    // Logout the existing user (if any)
    if (realmApp.currentUser) {
      await realmApp.currentUser.logOut();
    }

    const result = await emailPasswordLogin(email, password, realmApp);

    if (Object.hasOwn(result, 'error')) {
      setIsReady(true);
      return result as SignupLoginError;
    }

    const loggedInUserUid = (result as User).id;
    return loggedInUserUid;
  };

  const logOutUser = async (): Promise<boolean> => {
    await realmApp?.currentUser?.logOut();

    if (realmApp?.currentUser === null) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (realmApp !== null) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [realmApp]);

  const state = {
    realmApp,
    isReady,
  };

  const handlers = {
    getUserUid: () => withAuthContextReady(getUserUid, isReady),
    getUserEmail: () => withAuthContextReady(getUserEmail, isReady),
    getRoles: () => withAuthContextReady(getRoles, isReady),
    getActiveUser: () => withAuthContextReady(getActiveUser, isReady),
    getUserAccessToken: () => withAuthContextReady(getUserAccessToken, isReady),
    logInUser: (email: string, password: string) =>
      withAuthContextReady(logInUser, isReady, email, password),
    logOutUser: () => withAuthContextReady(logOutUser, isReady),
  };

  return (
    <AuthContext.Provider value={{ state, handlers }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext(): [
  AuthContext['state'],
  AuthContext['handlers']
] {
  const { state, handlers } = useContext(AuthContext);
  return [state, handlers];
}

// This wrapper ensures any handlers passed to it are only called when the AuthContext is ready
function withAuthContextReady<FnOutput>(
  fn: (...args: any[]) => FnOutput,
  isReady: boolean,
  ...args: any[]
): FnOutput {
  if (isReady) {
    return fn(...args);
  } else {
    throw new Error(
      "AuthContext isn't ready yet, please check isReady handler returned by useAuthContext to avoid this error."
    );
  }
}
