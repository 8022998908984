import { useEffect, useState } from 'react';
import getMongoRealm, { MongoRealmInstance } from './mongoRealm';

function useRealmApp() {
  const [app, setApp] = useState<MongoRealmInstance | null>(null);

  // Run in useEffect so that App is not created in server-side environment
  useEffect(() => {
    setApp(getMongoRealm());
  }, []);
  return app;
}

export default useRealmApp;
