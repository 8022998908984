// a local storage key that is used by us to track which lessons are completed by
// a learner within our app
//
// it maps to a value that is an object where each key is the lessonSlug, and the
// value itself is a boolean
//
// e.g. { 'what-is-retrofit': true } <- the learner has completed the 'what is retrofit' lesson
//
export const COMPLETED_LESSONS_KEY = 'completedLessons' as string;

// LEARNER CONTEXT AND STORAGE
/*
 * A local storage key to track identifying information about the learner.
 */
export const LEARNER_STORAGE_KEY = 'learner' as string;
export const PRE_SIGNUP_ONBOARDING_CACHE_KEY =
  'preSignupOnboardingCache' as string;

// ERROR MESSAGES
/*
 * error messages that are used throughout the app.
 */
export const SIGNUP_LOGIN_ERROR_MESSAGES = {
  EMPTY_EMAIL: 'Email cannot be empty!',
  EMPTY_PASSWORD: 'Password cannot be empty!',
  EMPTY_EMAIL_AND_PASSWORD: 'Email and password cannot be empty!',
  USER_CREATION_FAILED:
    'Something went wrong creating your account. Please try again, or contact support.',
};

/*
 * error messages used for password reset pages.
 */
export const PASSWORD_RESET_ERROR_MESSAGES = {
  INVALID_PASSWORD:
    'Your password must be at least 6 characters long and match the confirmation password.',
  EXPIRED_LINK:
    'Your password reset link has expired. Please request a new one.',
  UNKNOWN_ERROR:
    'Unknown error resetting password. Please try requesting a new reset link.',
};

export const SOMETHING_WENT_WRONG_ERROR_MESSAGE =
  'Something went wrong. Please try again, or contact support.';
